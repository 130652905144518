<template>
  <div class="all-login">  
    <Header />
    <div class="container">
      <div class="wrapper">
        <div class="login-holder">
          <div class="title-itens">Plano de Assinatura</div>
          <ul>
            <li>✔ Conteúdo cadastrado diariamente</li>
            <li>✔ Artes exclusivas para Feed e Stories</li>
            <li>✔ Receba insights para alavancar seu negócio</li>
            <li>✔ Tenha acesso ao material de apoio exclusivo do My Social Media</li>
            <li>✔ Selo personalizado em todas as artes</li>
            <li>✔ Vídeos, imagens e carrosséis profissionais para suas redes sociais</li>
          </ul>
        </div>

        <div>
          <button v-if="canPurchase" class="btn email-btn zoomIn" @click="buy()">
            <p v-show="!loading">{{ t("Assinar por R$ 89,90 / mês") }}</p>
            <div v-show="loading" class="loading-spinner"></div>
          </button>

          <button v-if="!canPurchase" class="btn btn-transparent email-btn zoomIn">
            <p>{{ t("Plano ativo (R$ 89,90 / mês)") }}</p>
          </button>

          <div class="subscription-button-description">
            <p>Assinatura mensal recorrente é obrigatória para a utilização do aplicativo</p>
          </div>
        </div>

        <div class="footer-subscription-text">
          Ao assinar você concorda que leu e aceitou os 
          <a href="https://mysm.com.br/docs/termos-my-social-media.pdf" target="_blank">
            Termos de Uso
          </a>
        </div>
      </div>     
    </div>
  </div>
</template>

<script>
import languageUtil from "../util/languageUtil";
import Header from "../components/Header";
export default {
  components: {
    Header,
  },
  data() {
    return {
      language: languageUtil.activeLanguage(),
      subscriptionPlan: {},
      loading: false,
      canPurchase: false
    };
  },
  created() {
    this.subscriptionPlan = state.subscription;
    this.canPurchase = state.subscription.canPurchase;
  },
  methods: {
    setLanguage(lang) {
      languageUtil.setLanguage(lang);
      this.language = languageUtil.activeLanguage();
      location.reload();
    },
    buy() {
      this.loading = true;
      var vm = this;
      try {
        store.order(vm.subscriptionPlan.id);
        store.when()
          .approved(() => {
            vm.loading = false;
            vm.canPurchase = false;
          });
      } catch(err) {
        this.loading = false;
        this.$swal({
          type: "error",
          title: "Transação Incompleta",
          text: "Não foi possível realizar a assinatura, por favor, tente novamente mais tarde",
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.subscription-button-description p {
  color: #f0f0f0;
  margin-top: 10px;
  font-size: 0.8em;
  text-align: center;
}

.btn-transparent {
  background: initial !important;
}

.btn-transparent p {
  color: var(--primary) !important;
}

.login-holder .title-itens {
  color: #f0f0f0;
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 40px;
}

.footer-subscription-text {
  color: #f0f0f0;
  text-align: center;
  a {
    color: #c89d87;
  }
}

.apple-btn,
.email-btn {
  width: 100%; 
}
.apple-btn {
  background: initial;
}
.apple-btn p {
  color: #fff;
}
.apple-btn p,
.email-btn p {
  font-family: fontBold;
  text-align: center;
  line-height: 26px;
  width: 300px;
  margin-left: 10px;
}
.btn-logo {
  position: absolute;
  width: 25px;
  left: 15px;
}
.all-login {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-color: var(--secondary);
  background-image: url("../assets/images/bg1.jpg");
  background-position: center center;
  background-size: cover;
  background-blend-mode: overlay;
}
.logo {
  position: relative;
  display: block;
  max-width: 200px;
  margin: 20px auto 0 auto;
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  min-height: 85vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.login-holder {
  width: 100%;
  ul li {
    color: #f0f0f0;
    font-size: 1.15em;
    margin-top: 12px; 
  }
  .subtitle.registered-user {
    text-align: center;
    margin-bottom: 25px;
  }
}

.warning,
.forgot-password {
  position: relative;
  font-size: 1.15em;
  color: #e1cd5c;
  text-align: center;
}

.forgot-password {
  letter-spacing: 1px;
  color: #fff;
}

.register-holder {
  position: relative;
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .subtitle {
    text-align: center;
  }
}

.languages-holder {
  position: absolute;
  top: 2.5rem;
  right: 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  .title {
    position: relative;
    font-size: 1.2em;
    font-family: fontRegular;
    width: 100%;
    color: #fff;
    text-align: right;
  }
  img {
    max-width: 36px;
    filter: saturate(0);
    opacity: 0.7;
    cursor: pointer;
    image-rendering: -webkit-optimize-contrast;
    transition: filter 0.2s linear, opacity 0.2s linear;
    &.active,
    &:hover {
      filter: saturate(1);
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 720px) {
  .languages-holder {
    top: 0.8rem;
    right: -0.4rem;
    gap: 6px;
    .title {
      font-size: 1.4em;
    }
    img {
      width: 28px;
    }
  }
}
</style>
