<template>
  <header>
    <div class="container">
      <div @click="goBack" class="back desktop-hidden">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </div>

      <img
        @click="$router.push('/home')"
        src="../assets/images/logo.png"
        alt="My Social Media Logo"
        class="logo desktop-hidden tablet-hidden"
      />

      <h1 class="page-title mobile-hidden">{{ t($route.meta.title) }}</h1>

      <div @click.stop="showMenu = !showMenu" class="menu-holder">
        <font-awesome-icon :icon="['fas', 'bars']" />
      </div>

      <transition
        enter-active-class="animated zoomIn"
        leave-active-class="animated zoomOut"
      >
        <nav
          data-html2canvas-ignore
          v-show="showMenu"
          v-click-outside="hideMenu"
        >
          <ul>
            <LanguageComp />
            <li class="desktop-hidden">
              <router-link to="/" class="create-account">
                <font-awesome-icon :icon="['fas', 'home']" />
                <p>{{ t("Início") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <router-link to="/programacao-conteudo" class="create-account">
                <font-awesome-icon :icon="['fas', 'calendar-alt']" />
                <p>{{ t("Programação de conteúdo") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <router-link to="/apoio-extra" class="create-account">
                <font-awesome-icon :icon="['fas', 'lightbulb']" />
                <p>{{ t("Apoio extra") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <router-link to="/meu-selo" class="create-account">
                <font-awesome-icon :icon="['fas', 'camera']" />
                <p>{{ t("Meu selo") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <router-link to="/meus-dados" class="create-account">
                <font-awesome-icon :icon="['fas', 'user']" />
                <p>{{ t("Meus dados") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <router-link :to="userPlanLink" class="create-account">
                <font-awesome-icon :icon="['fas', 'id-badge']" />
                <p>{{ t("Meu plano") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <a
                href="https://mysm.com.br/docs/termos-my-social-media.pdf"
                target="_blank"
                class="create-account"
                :class="{ active: $route.name == 'Terms' }"
              >
                <font-awesome-icon :icon="['fas', 'file']" />
                <p>{{ t("Termos de Uso") }}</p>
              </a>
            </li>
            <li>
              <a @click="logout" style="cursor: pointer">
                <font-awesome-icon
                  :icon="['fas', 'sign-out-alt']"
                  style="transform: scaleX(-1)"
                />
                <p>{{ t("Sair") }}</p>
              </a>
            </li>
          </ul>
        </nav>
      </transition>
    </div>
  </header>
</template>

<script>
import authentication from "../util/authentication";
import LanguageComp from "@/components/LanguageComp";

export default {
  components: {
    LanguageComp,
  },
  data() {
    return {
      opened: false,
      showMenu: false,
    };
  },
  computed: {
    isAppleUser() {
      return authentication.getUser().info.userType == "Apple";
    },
    userPlanLink() {
      return this.isAppleUser ? "assinatura/apple" : "meu-plano";
    },
  },
  methods: {
    logout() {
      authentication.logout();
    },
    hideMenu() {
      this.showMenu = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  position: relative;
  width: 100%;
  height: 100px;
  z-index: 9;
  .container {
    height: 100%;
    max-width: initial;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.back {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #e7e7e799;
  display: grid;
  place-items: center;
  margin-right: 35px;
  cursor: pointer;
  svg {
    font-size: 1.2em;
    color: var(--secondary);
  }
}

.logo {
  position: relative;
  max-height: 65%;
  margin-right: 45px;
}

.page-title {
  position: relative;
  font-size: 2em;
}

.menu-holder {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  svg {
    font-size: 1.6em;
  }
}

nav {
  position: absolute;
  top: 42px;
  right: 36px;
  width: 200px;
  padding: 15px;
  border-radius: var(--default-border-radius);
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);

  ul li {
    position: relative;
    width: 100%;
    height: 44px;

    &:not(:last-child) {
      border-bottom: 1px solid #00000015;
    }

    a {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-size: 1.2em;
        margin-left: 15px !important;
      }

      svg {
        font-size: 1.3em !important;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
}

@media only screen and (min-width: 721px) and (max-width: 1139px) {
}
</style>
